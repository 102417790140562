.blogContainer {
    background-color: #EFF6FF;
    position: relative;
}

.blogCardContainer {
    display: flex;
    // z-index: -1;
}

.navIconNext {
    position: absolute;
    top: 45%;
    right: 10%
}

.navIconPrev {
    position: absolute;
    top: 45%;
    left: 0%
}

.header {
    margin-left: 12rem; 
    margin-bottom: 30px;
}

@media (max-width: 768px) {
    .leftButton {
        left: 0;
        position: absolute;
    }
    .blogContainer {
        justify-content: start;
    }
    .header {
        margin-left: 10rem; 
    }
}

@media (max-width: 429) {
    .blogContainer {
        justify-content: start;
    }
    .header {
        margin-left: 2rem; 
    }
}

@media (min-width: 850) {
    .blogContainer {
        justify-content: start;
    }
    .header {
        margin-left: 2rem; 
    }
}