.gridSection {
	/* display: grid; */
	/* margin-bottom: 1.25rem;
	margin-top: 1.2rem; */
	/* grid-template-columns: repeat(1, minmax(0, 1fr)); */
	/* justify-content: center;
	justify-items: center; */
	/* width: 90vw; */
	margin-right: 1rem;
}

.header {
	display: flex;
	text-align: left;
	align-items: baseline;
	justify-content: space-between;
	margin: 1.5rem 0 .5rem 0;
}

.header div{
	margin-right: 10px;
	font-size: 1rem;
	font-weight: 700;
}

.header a{
	text-decoration: none;
	font-size: 0.8rem;
	font-weight: 700;
}

.productList {
	display: block;
}

.product {
	border-radius: 0.75rem;
	/* width: 18rem; */
	width: 12rem;
	transition-duration: 500ms;
	/* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06); */
	position: relative;
	padding: 10px 0;
	background-color: #F9FAFB;
}

.product:hover {
	--transform-scale-x: 1.05;
	--transform-scale-y: 1.05;
	/* box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04); */
}

.productImage {
	object-fit: contain;
	border-top-left-radius: 0.75rem;
	border-top-right-radius: 0.75rem;
	width: 12.2rem;
	height: 6rem;
}

.productContainer {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 1rem;
	padding-right: 1rem;
	/* width: 18rem; */
	width: 12rem;
}

.productBrand {
	margin-right: 0.75rem;
	font-size: 0.75rem;
	line-height: 1rem;
	color: #9ca3af;
	text-transform: uppercase;
}

.productName {
	display: block;
	font-size: .8rem;
	line-height: 1rem;
	font-weight: 500;
	color: #000000;
	text-transform: capitalize;
	overflow: hidden;
	/* text-overflow: ellipsis;
	white-space: nowrap; */
}

.productFooterContainer {
	display: flex;
	align-items: center;
}

.productPrice {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: .8rem;
	line-height: 1rem;
	font-weight: 600;
	color: #000000;
	cursor: auto;
}

.productMainPrice {
	margin-left: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: #4b5563;
	cursor: auto;
}

.productAddToCart {
	cursor: pointer;
	margin-left: auto;
	position: absolute;
	bottom: 0.3rem;
	right: 0.3rem;
	background-color: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
}

.productAddToCart:hover {
	transform: scale(1.1);
}

/* .productFavourite {
	margin-left: auto;
	position: absolute;
	bottom: 18.5rem;
	right: 0.5rem;
} */

.productFavourite:hover {
	transform: scale(1.1);
}

@media (min-width: 480px) {
	/* .gridSection {
		grid-template-columns: repeat(2, minmax(0, .8fr));
	} */
}
@media (min-width: 768px) {
	/* .gridSection {
		grid-template-columns: repeat(3, minmax(0, .8fr));
	} */
}
@media (min-width: 1024px) {
	/* .gridSection {
		grid-template-columns: repeat(4, minmax(0, .8fr));
	} */
}

@media (min-width: 1250px) {
	/* .gridSection {
		grid-template-columns: repeat(5, minmax(0, .9fr));
	} */
}

.carouselContainer {
	position: relative;
	/*Hides images bleeding off page*/
	overflow: hidden;
	/*Centers div*/
	margin: auto;
	width: 100%;
	height: 250px;
}

.carouselSlide {
	/*Flex allows image to bleed to end*/
	display: flex;
	width: 100%;
	height: 250px;
}

#prevbutton {
	/*Bases position of button on parent*/
	position: absolute;
	top: 50%;
	/*Moves button back up half its size to vertically-align*/
	margin-top: -15px;
	left: 3%;
	/*Brings layer to top*/
	z-index: 10;
	color: #2f4f4f;
	font-size: 30px;
	cursor: pointer;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

#nextbutton {
	position: absolute;
	top: 50%;
	margin-top: -15px;
	right: 3%;
	z-index: 10;
	color: #2f4f4f;
	font-size: 30px;
	cursor: pointer;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}

.productCarousalImages {
	background-color: transparent;
    border: none;
	padding: 0px 1px;
}